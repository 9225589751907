<template>
  <div>
    <div class="part1">
      <div class="item"
           v-for="(item,index) in list"
           :key="index">
        <div class="upic">
          <b-img blank
                 blank-color="#cba"
                 alt="placeholder"></b-img>
        </div>
        <div class="name">{{item.name}}</div>
        <div class="job">{{item.job}}</div>
        <div class="des">{{item.des}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tab2",
  data () {
    return {
      list: [
        { upic: "", name: "小明", job: "首席执行官", des: "毕业于牛津大学计算机系拿的博士学位，先后从事于腾讯、阿里巴巴拥有15年的行业经验，负责过qq邮箱，天猫、阿里云等大型项目部。" },
        { upic: "", name: "小明", job: "首席执行官", des: "毕业于牛津大学计算机系拿的博士学位，先后从事于腾讯、阿里巴巴拥有15年的行业经验，负责过qq邮箱，天猫、阿里云等大型项目部。" },
        { upic: "", name: "小明", job: "首席执行官", des: "毕业于牛津大学计算机系拿的博士学位，先后从事于腾讯、阿里巴巴拥有15年的行业经验，负责过qq邮箱，天猫、阿里云等大型项目部。" },
        { upic: "", name: "小明", job: "首席执行官", des: "毕业于牛津大学计算机系拿的博士学位，先后从事于腾讯、阿里巴巴拥有15年的行业经验，负责过qq邮箱，天猫、阿里云等大型项目部。" },
        { upic: "", name: "小明", job: "首席执行官", des: "毕业于牛津大学计算机系拿的博士学位，先后从事于腾讯、阿里巴巴拥有15年的行业经验，负责过qq邮箱，天猫、阿里云等大型项目部。" },
        { upic: "", name: "小明", job: "首席执行官", des: "毕业于牛津大学计算机系拿的博士学位，先后从事于腾讯、阿里巴巴拥有15年的行业经验，负责过qq邮箱，天猫、阿里云等大型项目部。" },
        { upic: "", name: "小明", job: "首席执行官", des: "毕业于牛津大学计算机系拿的博士学位，先后从事于腾讯、阿里巴巴拥有15年的行业经验，负责过qq邮箱，天猫、阿里云等大型项目部。" },
        { upic: "", name: "小明", job: "首席执行官", des: "毕业于牛津大学计算机系拿的博士学位，先后从事于腾讯、阿里巴巴拥有15年的行业经验，负责过qq邮箱，天猫、阿里云等大型项目部。" },
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.part1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem 0;
  width: 95%;
  margin: auto;
  .item {
    background: white;
    width: calc(50% - 0.4rem);
    margin-bottom: 0.8rem;
    border-radius: 6px;
    border: 1px solid #dadada;
    padding: 1rem;
    .upic {
      text-align: center;
      padding-bottom: 0.3rem;
      img {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
      }
    }
    .name {
      text-align: center;
      font-size: 1rem;
      padding-bottom: 0.2rem;
    }
    .job {
      text-align: center;
      font-size: 0.8rem;
      color: #999999;
      padding-bottom: 0.2rem;
    }
    .des {
      color: #999999;
      font-size: 0.8rem;
    }
  }
}
@media screen and (min-width: 576px) {
  .part1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 0;
    width: 70%;
    margin: auto;
    .item {
      background: white;
      width: calc(50% - 0.6rem);
      margin-bottom: 1.2rem;
      border-radius: 6px;
      border: 1px solid #dadada;
      padding: 2rem 1rem 3rem;
      .upic {
        text-align: center;
        padding-bottom: 1rem;
        img {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
        }
      }
      .name {
        text-align: center;
        font-size: 1.1rem;
        font-weight: 600;
        padding-bottom: 0.2rem;
      }
      .job {
        text-align: center;
        font-size: 0.8rem;
        color: #999999;
        padding-bottom: 0.2rem;
      }
      .des {
        font-size: 0.8rem;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .part1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 0;
    max-width: 1200px;
    margin: auto;
    .item {
      background: white;
      width: calc(25% - 0.6rem);
      margin-bottom: 1.2rem;
      border-radius: 6px;
      border: 1px solid #dadada;
      padding: 2rem 1rem 3rem;
      .upic {
        text-align: center;
        padding-bottom: 1rem;
        img {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
        }
      }
      .name {
        text-align: center;
        font-size: 1.1rem;
        font-weight: 600;
        padding-bottom: 0.2rem;
      }
      .job {
        text-align: center;
        font-size: 0.8rem;
        color: #999999;
        padding-bottom: 0.2rem;
      }
      .des {
        font-size: 0.8rem;
      }
    }
  }
}
</style>