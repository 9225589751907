<template>
  <div>
    <div class="part1">
      <div class="img">
        <b-img :src="require('../../assets/img/about/img1.png')"></b-img>
      </div>
      <div class="content">
        <div class="title">门窗CC</div>
        <div class="des">
          适用于工厂、门店的设计下单软件，提供门窗设计、拆单算料、型材优化、生产管理、成品出库、订单跟踪等多方面的产品解决方案，助力工厂、门店提高效率、节省成本，智能制造自动化转型，已超过3万+门窗厂使用。
        </div>
        <div class="see_fa">
          <b-button pill
                    target="_blank"
                    href="http://www.menccc.com/"
                    class="see"
                    variant="primary">查看详情</b-button>
        </div>

      </div>
    </div>
    <div class="part1">
      <div class="img">
        <b-img :src="require('../../assets/img/about/img2.png')"></b-img>
      </div>
      <div class="content">
        <div class="title">简画阳光房</div>
        <div class="des">
          支持手机、电脑、平板画阳光房效果图，现场勘查、沟通，拿出手机、平板，现场画阳光房，完美对接到工厂下单、支持各种房型渲染。
        </div>
        <div class="see_fa">
          <b-button pill
                    target="_blank"
                    href="http://www.menccc.com/index-sunroom.html"
                    class="see"
                    variant="primary">查看详情</b-button>
        </div>

      </div>
    </div>
    <div class="part1">
      <div class="img">
        <b-img :src="require('../../assets/img/about/img3.png')"></b-img>
      </div>
      <div class="content">
        <div class="title">柜柜APP</div>
        <div class="des">
          全屋定制行业的柜体设计神器，拆单出方案、设计算料、生产出库，一横一竖，快速设计，出图报价、生产跟单、与门店无缝对接，减少出错，快速交付，帮助工厂，三倍增长。
        </div>
        <div class="see_fa">
          <b-button pill
                    target="_blank"
                    href="https://www.eggrj.com/"
                    class="see"
                    variant="primary">查看详情</b-button>
        </div>

      </div>
    </div>
    <div class="part1">
      <div class="img">
        <b-img :src="require('../../assets/img/about/img4.png')"></b-img>
      </div>
      <div class="content">
        <div class="title">画门窗</div>
        <div class="des">
          极其简单的门窗设计神器，通过拖拽任何复杂的门窗，快速设计，设计完成，设计完成出3D效果，不需要编写复杂的公式，点击选择快速完成算料。
        </div>
        <div class="see_fa">
          <b-button pill
                    target="_blank"
                    href="https://windowcc.com/home"
                    class="see"
                    variant="primary">查看详情</b-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tab3",
  data () {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
.part1 {
  display: flex;
  flex-flow: column;
  padding: 3rem;
  width: 90%;
  margin: 2rem auto 0;
  border-bottom: 1px dashed #c1c1c1;
  &:last-child {
    border-bottom: none;
  }
  .img {
    text-align: center;
    img {
      width: 100%;
    }
  }
  .content {
    color: #333333;
    .title {
      font-size: 1.2rem;
      padding: 0.5rem 0;
    }
    .des {
      font-size: 0.8rem;
      margin-bottom: 1rem;
    }
    .see_fa {
      display: flex;
      flex-direction: row-reverse;
      .see {
        font-size: 0.8rem;
        padding: 0.5rem 2rem;
      }
    }
  }
}
@media screen and (min-width: 576px) {
  .part1 {
    display: flex;
    flex-flow: inherit;
    padding: 3rem 3rem;
    width: 70%;
    margin: 2rem auto 0;
    border-bottom: 1px dashed #c1c1c1;
    &:last-child {
      border-bottom: none;
    }
    .img {
      width: 50%;
      text-align: center;
      img {
        width: 18rem;
      }
    }
    .content {
      width: 50%;
      .title {
        font-size: 1.5rem;
        font-weight: 600;
        padding-bottom: 1.5rem;
      }
      .des {
        font-size: 0.9rem;
        min-height: 5rem;
      }
      .see_fa {
        display: flex;
        flex-direction: inherit;
        .see {
          font-size: 0.8rem;
          padding: 0.7rem 2rem;
        }
      }
    }
  }
}
</style>