<template>
  <div>
    <div class="part1">
      <div>
        无锡中巢建材科技有限公司是2019年11月份成立的公司，其主打的产品测量大师，主要服务于门窗、全屋定制行业的中高端门店，以测量为业务入口帮助门店提升核心竞争力，测量大师是一款软硬件相结合的门店管理工具，从触达终端客户到产品案例展示、产生意向、谈单、签单、测量、设计、下单到工厂、成品出库、运输、入户、安装，到最后的售后维保，从门店到终端客户到工厂一站式产品解决方案，测量大师还与新格尔门窗CC等系统数据互通，让门店的现场测量结果真实还原到工厂，进而实现不重复测量、不重复沟通、不重复跑现场，让时间不等人、人不等时间为主要价值。
      </div>
      <div>
        测量大师拥有强大的管理功能，不仅能调度人员工作，还能将手写本记录的内容实时同步到APP中，起到数据永不丢失，实时共享给对应人员，还能起到远程协作的作用。
      </div>
      <div>
        测量大师自上线以来，深受良木道、贝克洛、美顺门窗等门店的喜爱，上线7个月已有1.4万+的注册量。
      </div>
    </div>
    <div class="part2">
      <div :style="{width:'100%',height:'400px'}">
        <el-amap vid="amap"
                 class="amap-demo"
                 ref="map"
                 :zoom="zoom"
                 :events="events"
                 :center="center">
          <el-amap-info-window :position="center"
                               :content="formattedAddress"
                               visible="true">
            <div id="map_maker">
              <div class="title">无锡中巢建材科技有限公司-测量大师</div>
              <div class="address">地址：江苏省无锡市金融三街置业大厦6号楼17楼(宁波银行)</div>
              <div class="phone">电话：0510-85186908</div>
            </div>
          </el-amap-info-window>
        </el-amap>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tab1",
  data () {
    return {
      zoom: 14,
      center: [120.307011, 31.486173],
      lng: 0,
      lat: 0,
      formattedAddress: "无锡市滨湖区立德道与金融三街交叉口西北100米太湖新城置业大厦",
      coordinate: {
        "longitude": "",
        "latitude": ""
      },
      loaded: false,
      events: {
        init: o => {
          console.log(o.getCenter());
          // console.log(this.$refs.map.$$getInstance());
          // o.getCity(result => {
          //   console.log(result);
          // });

        },
        moveend: () => { },
        zoomchange: () => { },

      },
    }
  }
}
</script>

<style lang="less" scoped>
.part1 {
  padding: 1rem;
  font-size: 0;
  & > div {
    text-indent: 2rem;
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }
}
.part2 {
  padding: 0 1rem;
  #map_maker {
    .title {
      color: #2b85e4;
      font-size: 0.9rem;
      text-align: center;
      font-weight: 600;
      padding: 0.2rem 0 0.5rem;
    }
    .address,
    .phone {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }
  }
}
@media screen and (min-width: 576px) {
  .part1 {
    padding: 1rem;
    width: 75%;
    margin: auto;
    & > div {
      text-indent: 2rem;
      margin-bottom: 1rem;
      font-size: 1rem;
    }
  }
  .part2 {
    width: 75%;
    margin: auto;
    padding: 1rem;
    #map_maker {
      .title {
        color: #2b85e4;
        font-size: 0.9rem;
        text-align: center;
        font-weight: 600;
        padding: 0.2rem 0 0.5rem;
      }
      .address,
      .phone {
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}
</style>