<template>
  <div>
    <b-container fluid
                 class="part1">
      <b-row>
        <b-col class="col-12 text1 text-center text-lg-left">【关于我们】</b-col>
        <b-col class="col-12 text2 text-center text-lg-left">以提升经销商核心竞争力为主要思想，在未来5年内帮扶3000+经销商成功</b-col>
      </b-row>
    </b-container>
    <div class="part2">
      <div class="tab">
        <div class="item"
             v-for="(tab,index) in tabs"
             :key="index"
             @click="toggle(index,tab.view)"
             :class="{active:active==index}">
          {{tab.type}}
        </div>
      </div>
      <!--:is实现多个组件实现同一个挂载点-->
      <component :is="currentView"></component>
    </div>

    <myhead :background="'transparent'"></myhead>
    <foot></foot>
    <right></right>
  </div>
</template>

<script>
import myhead from '@/components/head.vue'
import foot from '@/components/foot.vue'
import right from '@/components/right.vue'
import tab1 from '@/views/About/tab1.vue'
import tab2 from '@/views/About/tab2.vue'
import tab3 from '@/views/About/tab3.vue'
export default {
  name: "about",
  data () {
    return {
      active: 0,
      currentView: 'tab1',
      tabs: [
        {

          type: '公司简介',
          view: 'tab1'
        },
        // {
        //   type: '核心团队',
        //   view: 'tab2'
        // },
        {
          type: '相关产品',
          view: 'tab3'
        }
      ]
    }
  },
  components: {
    foot,
    right,
    myhead,
    tab1,
    tab2,
    tab3
  },
  methods: {
    toggle (i, v) {
      this.active = i;
      this.currentView = v;
    }
  },
}
</script>

<style lang="less" scoped>
.part1 {
  background: url("../../assets/img/about/bac.png");
  background-size: cover;
  color: white;

  padding: 6rem 1rem;
  .text1 {
    font-size: 2rem;
    font-weight: 200;
    margin-bottom: 0.2rem;
  }
  .text2 {
    font-weight: 200;
    margin-bottom: 0.1rem;
  }
}
.part2 {
  padding-bottom: 2rem;
  .tab {
    height: 4rem;
    line-height: 4rem;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #dadada;
    .item {
      margin: 0 1.5rem;
      text-align: center;
      cursor: pointer;
      &.active {
        color: #007bff;
        border-bottom: 1px solid #007bff;
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .part1 {
    padding: 8rem 10rem;
    .text1 {
      font-size: 1.6rem;
    }
  }
  .part2 {
    .tab {
      font-size: 1rem;
      .item {
        margin: 0 2.5rem;
      }
    }
  }
}
</style>